<template>
  <div class="infoNavigation">
    <van-pull-refresh
      v-model="isLoading"
      success-text="刷新成功"
      @refresh="onRefresh"
    >
      <!-- 信息认证导航 -->
      <div class="box">
        <van-cell
          @click="toauthFace"
          title="活体认证"
          :value-class="faceSuccess ? 'blueText' : ''"
          is-link
          :value="faceSuccess ? '已认证' : '未认证'"
        />
        <!-- <van-cell
          v-if="loanSuccess === -1"
          @click="immediateStaging1"
          title="借款意向"
          is-link
          value-class="blueText"
          value="可更改"
        />
        <van-cell
          v-else
          @click="immediateStaging"
          title="借款意向"
          is-link
          :value-class="loanSuccess ? 'blueText' : ''"
          :value="loanSuccess ? '已填写' : '未填写'"
        /> -->
        <van-cell
          v-if="baseSuccess === -1"
          @click="baseInfo1"
          title="基础信息"
          is-link
          value-class="blueText"
          value="可更改"
        />
        <van-cell
          v-else
          @click="baseInfo"
          :value-class="baseSuccess ? 'blueText' : ''"
          title="基础信息"
          is-link
          :value="baseSuccess ? '已填写' : '未填写'"
        />
        <van-cell
          v-if="uploadSuccess === -1"
          @click="uploadImg1"
          title="授信附件资料"
          is-link
          value-class="blueText"
          value="可更改"
        />
        <van-cell
          v-else
          @click="uploadImg"
          title="授信附件资料"
          is-link
          :value-class="uploadSuccess ? 'blueText' : ''"
          :value="uploadSuccess ? '已上传' : '未上传'"
        />

        <div @click="subFile" :class="fourBool ? 'subBtn' : 'subBtn1'">
          提交
        </div>
      </div>
    </van-pull-refresh>
  </div>
</template>
<style lang="less" src="./index.less" scoped></style>
<style>
.box .blueText {
  color: #8486f8;
}
</style>
<script>
import { toRefs, onMounted, reactive } from 'vue'
import APP from '@/utils/App'
import { routerTag, GetUrlParams } from '../../utils/index'
import API from '../../server/api'
import { Toast } from 'vant'

export default {
  setup () {
    const state = reactive({
      creditState: -1,
      baseSuccess: null, // 基础信息填写
      // loanSuccess: null, // 借款意向填写
      faceSuccess: null, // 活体认证填写
      uploadSuccess: null, // 授权资料
      fourBool: null,
      creditNo: '',
      isLoading: false,
      deviceData: {},
      key: null
    })
    // 下拉刷新
    const onRefresh = () => {
      state.isLoading = false
      getStatus(state.creditNo)
    }
    // 跳转到人脸认证
    const toauthFace = () => {
      // eslint-disable-next-line eqeqeq
      if (state.faceSuccess === 1) {
        Toast.fail('不能重复认证哦！')
        return false
      } else {
        APP.JUMP_TO(`${routerTag}authFace`)
      }
    }
    // 提额，可更改的
    // const immediateStaging1 = () => {
    //   APP.JUMP_TO(`${routerTag}immediateStaging?key=1&edit=true`)
    // }
    // 不可以更改，只能提交一次
    // const immediateStaging = () => {
    //   // 修改
    //   if (state.loanSuccess === 1) {
    //     Toast.fail('不能重复提交哦！')
    //     return false
    //     // APP.JUMP_TO(`${routerTag}immediateStaging?key=` + 1)
    //   } else {
    //     // 新增
    //     APP.JUMP_TO(`${routerTag}immediateStaging?key=` + 0)
    //   }
    // }
    const baseInfo1 = () => {
      APP.JUMP_TO(`${routerTag}baseInfo?key=` + 1)
    }
    const baseInfo = () => {
      if (state.baseSuccess ===1) {
        // 修改
        //  Toast.fail('不能重复提交哦！')
        //   return false
        APP.JUMP_TO(`${routerTag}baseInfo?key=` + 1)
      } else {
        // 新增
        // APP.JUMP_TO(`${routerTag}baseInfo?key=` + 1)
        APP.JUMP_TO(`${routerTag}baseInfo?key=` + 0)
      }
    }
    const uploadImg1 = () => {
      APP.JUMP_TO(`${routerTag}supplementImg`)
    }
    const uploadImg = () => {
      if (state.uploadSuccess === 1) {
        // 补充资料、提额资料，如果提交过一次图片，他就又变成1了，
        // 这里如果是1 的情况下，再判断下状态
        // Toast.fail('不能重复提交哦！')
        let u = `${routerTag}uploadImg`
        if (state.creditState > 2) u = `${routerTag}supplementImg`
        APP.JUMP_TO(u)
      } else {
        // 普通授信
        // console.log('普通授信')
        // return false
        APP.JUMP_TO(`${routerTag}uploadImg`)
      }
    }
    const getStatus = async (creditNus) => {
      const result = await API.getUserStatus({ creditNo: creditNus })
      state.faceSuccess = result[0].processState
      // state.loanSuccess = result[1].processState
      state.baseSuccess = result[1].processState
      state.uploadSuccess = result[2].processState

      /**
       *  0 还没有填写
       *  1 已经填写了，不能修改
       *  -1 可以修改
       *  这里来判断下面的按钮是否可以点击，进行提交
       * */

      let bool = true
      if (Array.isArray(result)) {
        result.forEach(item => {
          if (item.processState === 0) {
            bool = false
          }
        })
      }
      state.fourBool = bool

      //  这里是冗余代码，为了根据当前用户的状态来判断可以编辑的内容
      try {
        const phone = localStorage.getItem('cellPhone')
        const res = await API.isUser({ phone })
        state.creditState = res.creditState
      } catch (e) {
        console.log(e, '报错')
      }

    }
    // 提交
    const subFile = () => {
      if (!state.faceSuccess) {
        Toast.fail('请先进行人脸认证!')
        return
      }
      // if (!state.loanSuccess) {
      //   Toast.fail('请先填写借款意向!')
      //   return
      // }
      if (!state.baseSuccess) {
        Toast.fail('请先填写基础信息!')
        return
      }
      if (!state.uploadSuccess) {
        Toast.fail('请上传资料!')
        return
      }
      // eslint-disable-next-line eqeqeq
      if (state.key == 2) {
        console.log(state.key, '9999')
        API.subAddMoney()
        Toast.success('已提交')
        setTimeout(function () {
          APP.BACK(-1)
        }, 1000)
      } else {
        console.log(state.key, '888')
        API.gotoLoan({
          creditNo: state.creditNo,
          jsonObject: state.deviceData
        })
        Toast.success('已提交')
        setTimeout(function () {
          APP.BACK(-1)
        }, 1000)
      }
    }

    // 获取公用数据
    const getCommonData = async () => {
      await APP.COMMONDATA().then((res) => {
        console.log(res, '公用数据')
        state.deviceData = res
      })
    }

    onMounted(() => {
      // console.log(GetUrlParams(window.location.href).key,'地址');
      state.key = GetUrlParams(window.location.href).key
      state.creditNo = localStorage.getItem('creditNo')
      getStatus(state.creditNo)
      getCommonData()
      APP.SET_TITLE('授权资料')
      if (!APP.BROWSER.android) {
        APP.PAGE_WILL_LOAD(function () {
          window.location.reload()
        })
      } else {
        APP.PAGE_WILL_LOAD(function () {
          console.log(state.creditNo, '授信编号')
          getStatus(state.creditNo)
        })
      }
    })
    return {
      ...toRefs(state),
      toauthFace,
      // immediateStaging,
      // immediateStaging1, // 可更改
      baseInfo,
      baseInfo1, // 可更改
      uploadImg,
      uploadImg1,
      subFile,
      onRefresh
    }
  }
}
</script>
